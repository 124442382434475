body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.textAlignCenter {
  text-align: center!important;
}

.textAlignCenter > input
{
  text-align: center!important;
}

.textAlignCenter > button
{
  text-align: center!important;
}

.centeredButton 
{
  margin-left: auto!important;
  margin-right: auto!important;
  /* width: 6em */
}


.tableFont {
  font-size: 15px; /* was 18  */
  text-align: center;
}
.tableFontProportions {
  font-size: 15px;
  text-align: center;
  color: #636363;
}

.tableHeaderFont {
  font-size: 18px;
  text-align: center;
}

.moreDetails {
  font-size: 20px;
  text-align: center;
  color: lightslategrey
}

.placeholderCentering > input::placeholder {
  text-align: center;
}

textarea {
  resize: none!important;
}

.ui.mini.modal {
  width: 200px!important;
}

.clickable {
  cursor: pointer;
}

.centertext {
  height: 20px!important;
  line-height: 20px!important;
}

.centertextdiv {
  display: table; 
  height: 50px; 
  overflow: hidden;
}

.centertexttext {
  display: table-cell; vertical-align: middle;
}

.ui.orange.button, .ui.orange.buttons .button {
  background-color: #f58220!important;
}

.leftright {
  display: flex;                  /* establish flex container */
  flex-direction: row;            /* default value; can be omitted */
  flex-wrap: nowrap;              /* default value; can be omitted */
  justify-content: space-between; /* switched from default (flex-start, see below) */
}
.leftright > div {
  width: 500px;
  /* border: 2px dashed red; */
}

.swal-button--confirm {
  /* padding: 7px 19px;
  border-radius: 2px; */
  background-color: #f58220;
  /* font-size: 12px;
  border: 1px solid #3e549a;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3); */
}

.swal-button--danger {
  background-color: #e64942;
}

.hint {
  padding-top: 20px;
  max-width: 380px;
  margin: 0 auto;
  line-height: 1.2em;
  color: #000;
  text-align: center;
}

.smallPaddingBottom {
  padding-bottom: 10px;
}